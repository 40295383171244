const apiRoutes = {
  getUserJobs: '/get_user_jobs',
  getCounterAnalytics: '/get_counter_analytics',
  getUserFailedJobs: '/get_user_failed_jobs',
  getCustomToken: '/auth/genarate_custom_token',
  updateClaims: '/auth/update_claims',
  getStripeProducts: '/payment/get_products',
  uploadResume: '/jobs/upload_resume',
  getProfiles: '/data_management/get_profiles',
  startOrStop: '/jobs/action',
  addSearchLinks: '/data_management/add_search_link',
  addProfile: '/data_management/add_profile',
  addApplicant: '/data_management/add_profile_applicant',
  getRoles: '/user_management/get_roles',
  addUser: '/user_management/user',
  getUsers: '/user_management/get_company_users',
  inviteUser: '/user_management/invite_user',
  newPassword: '/user_management/update_user_password',
  scheduleJobApplying: '/data_management/schedule_time',
  getScheduler: '/data_management/get_scheduler',
  addEnterpriseUser: '/user',
  getPaymentInfo: '/payment/get_payment_info',
  getPlanInfo: '/payment/get_plan_info',
  cancelSubscription: '/payment/cancel_subscription',
  increaseProfileLimit: '/data_management/increase_profile_limit',
  deleteProfile: '/data_management/delete_profile',
  deleteUser: '/user_management/delete_user',
  getDiscoveredJobs: '/discovery/get_discovered_jobs',
  getDiscoverPortals: '/discovery/get_discovery_portal',
  uploadResume: '/data_management/upload_resume',
  stepForm: '/data_management/form_steps',
  selectDiscoveryPortals: '/data_management/select_discovery_portals',
  connectToJobPortal: '/data_management/connect_portal',
  getLocations: '/discovery/locations',
  getStripePricesV2: '/payment/v2/get_products',
  deleteFetchJobsNamespace: '/discovery/delete_fetch_job_namespace',
  startDiscoveringJobs: '/discovery/start_discovery',
  updateJobSeenStatus: '/discovery/update_seen',
  disconnectPortal: '/data_management/disconnect_portal',
  pauseAutomation: '/data_management/pause_automation',
  getPortalsInfo: '/data_management/get_portal_info',
  getJobPortals: '/jobs/get_job_portals',
  getOverviewData: '/data_management/get_overview_data',
  getApplyOverviewData: '/data_management/get_apply_overview',
  getStageOverviewData: '/data_management/get_stage_overview_data',
  startOrStopScheduler: '/jobs/action',
  getJobTitleSuggestions: '/data_management/get_chat_gpt_suggestion',
  getUserAnalytics: '/data_management/get_user_analytics',
  surveyForm: '/auth/survey_form',
  updateJobStage: '/jobs/update_job_stage',
  getJobStagesCount: '/jobs/get_job_stages_counts',
  addUserDeviceInfo: '/auth/add_user_device_info',
  extendTrail:'/data_management/extend_trial',
  googleMapAutoComplete:
    'https://maps.googleapis.com/maps/api/place/autocomplete/json',
};

export default apiRoutes;
